@import "_variables";
html{
  font-size: 62.5%;
  @media(max-width: 767px){
    font-size: 62.5%;
  }
}
body{
    font-family: $global_font;
    color: $body_text_color;
}
.title-image-back{
  background-color: $primary_color;
  .tag{
    h1{
      font-size: 3rem;
      font-weight: normal;
    }
  }
}
.background{
  &-primary{
    background-color: $primary_color;
  }
  &-secondary{
    background-color: $secondary_color;
  }
}
.text{

}

p {
	font-size: 1.9rem;
	font-family: 'Karla', Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 3rem;
	color: #545454;
	text-align: left;
	}

h1 {
	font-size: 4.2rem;
	font-family: $global_font;
	font-weight: 400;
	font-style: normal;
	line-height: 120%;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	}

h2 {
	font-size: 2.8rem;
	font-family: $global_font;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	text-transform: uppercase;
	color: $primary_color;
	}

h3 {
	font-size: 2.4rem;
	font-family: $global_font;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	color: $body_text_color;
	-webkit-font-smoothing: antialiased;
	}

h4 {
	font-size: 2.2rem;
	font-family: $global_font;
	font-weight: 400;
	font-style: normal;
	line-height: 120%;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: $primary_color;
	}

h5 {
	font-size: 2.1rem;
	font-family: $global_font;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	color: $body_text_color;
	}


.title-image-back{
  height: auto;
  padding: 20px 0;
  @media(max-width: 767px){
    padding: 15px 0;
  }
  .tag{
    position: relative;
    @media(max-width: 767px){
      left: 0%;
    }
    h1{
      color:#F2F0E9;
      font-family: $brand_font_2;
      font-size: 4.2rem;
      text-shadow: none;
      letter-spacing: 0.125em;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 2.5rem;
      }
    }
  }
}
/* HEADER */

nav.navbar.bootsnav{
  .navbar-toggle{
    span{
      padding-right: 5px;
      @media(max-width: 767px){
        display: none;
      }
    }
    @media(max-width: 991px){
      float: right;
      right: 0;
      margin-right: 0;
    }
  }
  ul.nav{
    li{
      a{
        padding: 30px 15px 5px 15px;
        font-size: 1.6rem;
      }
    }
    li.dropdown{
     a.dropdown-toggle:after{
       display: none;
     }
     &.on{
       a{
         border-bottom: 5px $primary_color solid;
       }
     }
     ul.dropdown-menu{
       width: auto;
       border-top: none;
       border:none;
       li{
         a{
           text-align: right;
           border: none;
         }
       }
       li:nth-child(even) {
         a{
           background: #D3CEBF!important;
         }
       }
       li:nth-child(odd) {
         a{
           background: #F2F0E9!important;
         }
       }
     }
   }
 }
}
.top-header-menu{
  margin-top: 20px;
  @media(max-width: 991px){
    margin-top: 0px;
    display: none;
  }
  ul{
    list-style-type: none;
    float: right;
    li{
      display: inline;
      float: left;
      padding-left: 20px;
      a{
        text-transform: uppercase;
        color: $body_text_color;
        font-size: 1.6rem;
      }
    }
  }
}


.navbar-header{
  @media(max-width: 991px){
    width: 100%!important;
  }
}
.navbar-toggle{
  @media(max-width: 991px){
    display: block!important;
  }
}
.navbar-collapse{
  @media(max-width: 991px){
    display: none!important;
  }
}
.navbar-toggle:hover{
  background-color: transparent;
}

.navbar-brand{
  height: auto;
  padding: 20px 10px;
  img{
    @media(min-width: 1400px){
      max-height: 140px;
      &.logo-sm{
        display: none!important;
      }
    }
    @media(max-width: 1399px){
      max-height: 75px;
      &.logo{
        display: none!important;
      }
    }
    @media(min-width: 1199px) and (max-width: 1399px){
      max-height: 75px;
    }
    @media(max-width: 991px){
      max-height: 55px;
    }
  }
}

nav{
  &.navbar.bootsnav{
    border-top: 20px #D3CEBF solid!important;
    ul.nav{
      margin-top: 20px;
      position: absolute;
      @media(max-width: 991px){
        position: relative;
      }
      right: 0;
      li{
        a{
          text-transform: uppercase;
        }
      }
    }
  }
}

.sidenav{
  background-color: #D3CEBF;
  li .sub-menu li a{
    position: relative;
    z-index: 100;
  }
  a{
    color: $body_text_color;
    &:hover, &:focus{
      @media(max-width: 992px){
        background-color: transparent!important;
      }
    }
  }
  .social-nav{
    display: inline;
    list-style-type: none;
    float: right;
    li{
      display: inline;
      float: left;
      a{
        padding: 8px;
      }
    }
  }
  li{
    a{
      color: $body_text_color;
      font-weight: bold;
      font-size: 1.6rem;
    }
    .sub-menu{
      margin-right: 30px;
      float: right;
      list-style-type: none;
      li{
        a{
          font-weight: normal;
        }
      }
    }
  }
}

/* END HEADER */

.btn{
  border-radius: 0;
  font-family: $brand_font_2;
  text-transform: uppercase;
}

.content-columns{
  background-size: cover!important;
}

/* HOME BILLBOARD*/
.home-billboard{
  padding-top: 10vh;
  padding-bottom: 30vh;
  height: 70vh;
  @media(max-width: 767px){
    height: 45vh!important;
  }
  .quotes{
    position: absolute;
    @media(min-width: 992px){
      left: -60px;
    }
    @media(max-width: 991px){
      display: none;
    }
    max-height: 50px;
  }
  .content-container{
    position: relative;
    z-index:5;
  }
  .btn{
    float: left;
    margin-right: 10px;
    @media(max-width: 1199px){
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .overlay-color{
    opacity: 0.8;
    top:0;
    background: $primary_color;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .title{
    text-align: left;
    text-transform: uppercase;
    color: #F2F0E9;
    @media(max-width: 767px){
      line-height: 90%;
      text-align: center!important;
    }
    .top-line{
      font-family: 'Alice', Georgia, serif;
      float: left;
      width: 100%;
      font-size: 3.2rem;
      @media(max-width: 991px){
        font-size: 2.5rem;
      }
    }
    .bottom-line{
      text-align: center;
      font-size: 6rem;
      margin-left: 10rem;
      @media(max-width: 991px){
        font-size: 3rem;
        margin-left: 0rem;
      }
      @media(max-width: 767px){
        margin-left: 0;

      }
    }
  }
  p{
    color: #F2F0E9;
    text-align: left;
    font-size: 2.4rem;
    line-height: 2.7rem;
    @media(max-width: 767px){
      display: none;
    }
  }
  a{
    color: #D3CEBF;
  }
  .overlay-bar{
    background-color: #000000;
    position: absolute;
    top: 0;
    right:5%;
    @media(max-width: 991px){
      right: 2%;
    }
    @media(max-width: 767px){
      display: none;
    }
    height: 100%;
    max-width: 250px;
    padding-top:15vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    img{
      width: 100%;
    }
    .link{
      text-align: left;
      margin-top: 15px;
      a{
        color: #F2F0E9;
        width: 100%;
        text-transform: uppercase;
        font-family: Karla;
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.1rem;
      }
    }
  }
}

.overlay-bar-mobile{
  background-color: #000000;
  a{
    color: #F2F0E9;
    width: 100%;
    text-transform: uppercase;
    font-family: Karla;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.1rem;
  }
  .image{
    text-align: right;
    img{
      float:right;
    }
  }
  .link-container{
    margin-top: 85px;
  }
}
/* END HOME BILLBOARD */

.slick-slide{
  border:5px #fff solid;
  transform: scale(1.75);
  img{
    height: 500px;
  }
  &.slick-center {
	opacity: 1;
	transform: scale(1.75);
}
}
.owl-prev, .owl-next{
  position: absolute;
  bottom: 50%;
  z-index: 5;
  background: transparent;
  border: 0;
  color: #F2F0E9;
  i{
    font-size: 2.4rem;
    text-shadow: 2px 2px 2px rgba(0, 0,0, 0.5);
  }
  &:focus{
    outline: none;
  }
}
.owl-prev{
  left: 2%;
}
.owl-next{
  right:2%;
}
/* START FOOTER */

.base-footer{
  background-color: #000000;
  .box{
    background-color: $primary_color;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 1.8rem;
  }
  p, a{
    margin: 15px 0;
    font-size: 1.65rem!important;
    text-align: center;
  }
}
/* END FOOTER */


/* HOME PAGE */

.home-types{
  .action-buttons{
    width: 100%;
  }
  .content-column{
    @media(max-width: 767px){
      padding-top: 10px;
      padding-bottom: 0px;
    }
  }
  h3{
    color: #D3CEBF!important;
    font-size: 2rem;
    text-transform: uppercase;
    font-family: 'Alice', Georgia, serif;
    @media(max-width: 767px){
      display: none;
    }
  }
  img{
    @media(max-width: 767px){
      display: none;
    }
  }
  p{
    margin: 0;
  }
}
